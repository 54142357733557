<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col>
            <b-form-group
              label="Başlangıç Tarihi"
              label-for="sdate"
            >
              <b-form-datepicker
                id="sdate"
                v-model="sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Bitiş Tarihi"
              label-for="edate"
            >
              <b-form-datepicker
                id="edate"
                v-model="edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <b-button
              variant="primary"
              @click="getReport"
            >
              <FeatherIcon icon="BarChartIcon" />
              <span class="align-middle">Raporu Görüntüle</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <div
        v-if="loading === true"
        class="text-center mb-2"
      >
        <b-spinner />
      </div>
      <div v-if="report.length > 0">
        <div
          v-for="(row,key) in report"
          :key="key"
        >
          <automotive-table
            :report-data="row"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BFormDatepicker, BButton, BFormGroup, BSpinner,
} from 'bootstrap-vue'
import AutomotiveTable from '@/views/Reports/Marketing/components/AutomotiveTable.vue'

export default {
  name: 'Automotive',
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BSpinner,
    AutomotiveTable,
  },
  data() {
    return {
      sdate: null,
      edate: null,
      loading: null,
    }
  },
  computed: {
    report() {
      const data = this.$store.getters['marketingAutomotiveReport/getAutomotiveReport']
      if (data.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
  methods: {
    getReport() {
      this.loading = true
      this.$store.dispatch('marketingAutomotiveReport/automotiveReport', {
        sdate: this.sdate,
        edate: this.edate,
      })
    },
  },
}
</script>

<style scoped>

</style>
