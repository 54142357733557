<template>
  <b-card :title="reportData.name">
    <b-table-simple hover>
      <b-tbody>
        <b-tr v-if="reportData.facebook !== null">
          <b-th>
            Facebook Form
          </b-th>
          <b-td class="width-200">
            {{ reportData.facebook }}
          </b-td>
        </b-tr>
        <b-tr v-if="reportData.google_ads !== null">
          <b-th>
            Google Ads
          </b-th>
          <b-td class="width-200">
            {{ reportData.google_ads }}
          </b-td>
        </b-tr>
        <b-tr v-if="reportData.instagram !== null">
          <b-th>
            Instagram Form
          </b-th>
          <b-td>{{ reportData.instagram }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.sahibinden !== null">
          <b-th>
            Sahibinden.com
          </b-th>
          <b-td>{{ reportData.sahibinden }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.web !== null">
          <b-th>
            Web Form
          </b-th>
          <b-td>{{ reportData.web }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.web_dist !== null">
          <b-th>
            Web Distribütör
          </b-th>
          <b-td>{{ reportData.web_dist }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.whatsapp !== null">
          <b-th>
            Whatsapp
          </b-th>
          <b-td>{{ reportData.whatsapp }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.digital_sales !== null">
          <b-th>
            Dijital Satış
          </b-th>
          <b-td>{{ reportData.digital_sales }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.service_web !== null">
          <b-th>
            Web Servis Randevusu
          </b-th>
          <b-td>{{ reportData.service_web }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.service_facebook !== null">
          <b-th>
            Facebook Servis Randevusu
          </b-th>
          <b-td>{{ reportData.service_facebook }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.service_google_ads !== null">
          <b-th>
            Google Ads Servis Randevusu
          </b-th>
          <b-td>{{ reportData.service_google_ads }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.service_instagram !== null">
          <b-th>
            Instagram Servis Randevusu
          </b-th>
          <b-td>{{ reportData.service_instagram }}</b-td>
        </b-tr>
        <b-tr v-if="reportData.service_digital !== null">
          <b-th>
            Dijitalden Servise Gelen
          </b-th>
          <b-td>{{ reportData.service_digital }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BTableSimple, BCard, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'

export default {
  name: 'AutomotiveTable',
  components: {
    BTableSimple, BCard, BThead, BTbody, BTr, BTh, BTd,
  },
  props: {
    reportData: {
      type: Object,
    },
  },
}
</script>

<style scoped></style>
